import React, { Component } from 'react'
import styles from './index.module.scss'

class Input extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      isFocus: false,
      showRequired: false
    }
  }
  onFocus = () => {
    this.setState({ isFocus: true })
  }
  onBlur = () => {
    this.setState({ isFocus: this.state.value.length > 0 ? true : false })
  }
  onChange = (e) => {
    if (e.target.value.length > 0) this.setState({ showRequired: false })
    if (this.props.onChange) this.props.onChange(e.target.value)
    this.setState({ value: e.target.value })
  }
  showRequired = () => {
    this.setState({ showRequired: true })
  }
  hideRequired = () => {
    this.setState({ showRequired: false })
  }
  render() {
    return (
      <div className={`w-full mt-4`}>
        <div className={`w-full border-b border-black`}>
          <label className={`${this.state.isFocus ? styles.inFocus : styles.inBlur} block uppercase tracking-wide text-black text-sm mb-2`} htmlFor={this.props.id}>{this.props.label}*</label>
          <input onChange={ this.onChange } onFocus={ this.onFocus } onBlur={ this.onBlur } className={`appearance-none bg-transparent border-none w-full text-black mr-3 py-1 px-2 leading-tight focus:outline-none pl-0`} id={this.props.id} type={this.props.type} placeholder={``} aria-label={this.props.aria}></input>
        </div>
        <p className={`${this.state.showRequired ? `opacity-100` : `opacity-0`} text-xs uppercase text-red-700 mt-3`}>{this.props.requiredMessage}</p>
      </div>
    )
  }
}

export default Input