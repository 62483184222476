import React, { useEffect, useState } from 'react'
import { graphql, Link } from 'gatsby'
import styles from './index.module.scss'
import Layout from 'components/layout'
import SEO from 'components/seo'
import Constants from 'constants'
import intl from '@/intl'
import toTitleCase from 'titlecase'
import emailValidator from 'email-validator'
import Input from 'components/input'

const NewsletterPage = (props) => {
  const pad = `p-4`
  const data = props.data.allContentfulNewsletter.nodes[0]
  const successMessage = data.subscriptionSuccessMessage.subscriptionSuccessMessage
  const failMessage = data.subscriptionFailMessage.subscriptionFailMessage
  const requiredFieldMessage = data.requiredFieldMessage.requiredFieldMessage
  const lang = props.pageContext.code
  const radioBtn = React.createRef()
  const emailInput = React.createRef()
  const firstNameInput = React.createRef()
  const lastNameInput = React.createRef()
  const [userEmail, setUserEmail] = useState('')
  const [subscribeMessage, setSubscribeMessage] = useState()
  const [userFirstName, setUserFirstName] = useState('')
  const [userLastName, setUserLastName] = useState('')
  const [userPrivacy, setUserPrivacy] = useState(false)
  const postToMailchimp = (item) => {
    fetch(`${Constants.FUNCTIONS_URL}subscribe_to_mailchimp`, { method: 'POST', headers: { 'Content-Type': 'application/json; charset=utf-8' }, body: JSON.stringify(item) })
      .then(() => {
        setSubscribeMessage({ type: 'success', message: successMessage })
      })
      .catch(() => {
        setSubscribeMessage({ type: 'fail', message: failMessage })
      })
  }
  const onSubmit = (event) => {
    event.preventDefault()
    if (emailInput.current.state.value.length < 1) emailInput.current.showRequired()
    if (firstNameInput.current.state.value.length < 1) firstNameInput.current.showRequired()
    if (lastNameInput.current.state.value.length < 1) lastNameInput.current.showRequired()
    if (!userPrivacy || userEmail.length < 1 || !emailValidator.validate(userEmail)) return
    // !!!!!!!!!!!! Weird behaviour but it works !!!!!!!!!!!!
    try {
      fetch(`https://pierreaugustinrose.us19.list-manage.com/subscribe/post-json?u=${process.env.GATSBY_MAILCHIMP_U}&id=${process.env.GATSBY_MAILCHIMP_ID}&MERGE0=${userEmail}&MERGE1=${userFirstName}&MERGE2=${userLastName}`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        setSubscribeMessage({ type: 'success', message: successMessage })
      })
      .catch(error => {
          // !!!!!!!!!!!! Even with a cross origin issue the record goes to the mailchimp server !!!!!!!!!!!!
          setSubscribeMessage({ type: 'success', message: successMessage })
        })
    }
    catch (error) {
      setSubscribeMessage({ type: 'fail', message: failMessage })
    }
    // postToMailchimp({ email: userEmail, firstName: userFirstName, lastName: userLastName })
  }
  const onRadioClick = (e) => {
    setUserPrivacy(!userPrivacy)
  }
  const onEmailChange = (val) => {
    setUserEmail(val)
  }
  const onFirstNameChange = (val) => {
    setUserFirstName(val)
  }
  const onLastNameChange = (val) => {
    setUserLastName(val)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(() => {
    radioBtn.current.checked = userPrivacy
  })
  return (
    <Layout>
      <SEO
        title={toTitleCase(intl(`newsletter`))}
        lang={props.pageContext.code}
        pathname={props.location.pathname}
      />
      <div className={styles.parent}>
        <div className={``}>
          <Link className={`lg:hidden absolute z-30 p-4 pl-logo`} to={`/${lang}`}>
            <img alt={`Pierre Augustin Rose`} src={`/logo.svg`} />
          </Link>
          <section className={`w-3/7 lg:fixed lg:right-0 text-base pt-32 lg:pt-0`}>
            <h1 className={`uppercase ${pad} p-4 pl-logo pt-4`}>{intl(`newsletter`)}</h1>
          </section>
          <div className={`pt-16 lg:pt-32`}>
            <div className={`block lg:flex`}>
              <div className={`lg:w-4/7 lg:flex-auto upppercase ${pad} p-4 pl-logo uppercase`}>{data.subscribeTitle}</div>
              <div className={`lg:w-3/7 lg:flex-auto ${pad} p-4 pl-logo`}>
                <form onSubmit={onSubmit}>
                  <p className={`pb-6`}>{data.introText.introText}</p>
                  <Input
                    ref={firstNameInput}
                    label={data.firstNameFieldTitle}
                    id={`firstname-email`}
                    type={`text`}
                    aria={`First Name`}
                    onChange={onFirstNameChange}
                    requiredMessage={requiredFieldMessage}
                  />
                  <Input
                    ref={lastNameInput}
                    label={data.lastNameFieldTitle}
                    id={`lastname-email`}
                    type={`text`}
                    aria={`Last Name`}
                    onChange={onLastNameChange}
                    requiredMessage={requiredFieldMessage}
                  />
                  <Input
                    ref={emailInput}
                    label={data.emailFieldTitle}
                    id={`newsletter-email`}
                    type={`email`}
                    aria={`Email Address`}
                    onChange={onEmailChange}
                    requiredMessage={requiredFieldMessage}
                  />
                  <div className='mt-6 flex items-center justify-between'>
                    <div className='flex items-center'>
                      <input ref={radioBtn} onClick={ onRadioClick } id='read_privacy_policy' type='radio' className={``} />
                      <label htmlFor='read_privacy_policy' className='block leading-5 text-black text-sm uppercase'>
                        {data.privacyPolicyButton}
                      </label>
                    </div>
                  </div>
                  <div className={`${styles.privacyPolicy} uppercase text-xs mt-4`} dangerouslySetInnerHTML={{__html: data.privacyPolicyText.childMarkdownRemark.html}} />
                  <button type={`submit`} className={`${(!userPrivacy || userEmail.length < 1 || !emailValidator.validate(userEmail)) ? styles.btnDisabled : ''} block border-black border w-full text-center py-8 text-sm uppercase mt-12`}>{data.submitButtonText}</button>
                  { subscribeMessage && <p className={`${ subscribeMessage.type === `success` ? `text-green-600` : `text-red-600`} uppercase text-sm mt-2 mb-12`}>{ subscribeMessage.message }</p> }
                  <div className={`h-12 lg:h-10`}></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const NewsletterQuery = graphql`
  query NewsletterQuery($code: String) {
    allContentfulNewsletter(filter: {node_locale: {eq: $code}}) {
      nodes {
        subscribeTitle
        submitButtonText
        privacyPolicyButton
        introText {
          introText
        }
        emailFieldTitle
        firstNameFieldTitle
        lastNameFieldTitle
        subscriptionSuccessMessage {
          subscriptionSuccessMessage
        }
        subscriptionFailMessage {
          subscriptionFailMessage
        }
        requiredFieldMessage {
          requiredFieldMessage
        }
        privacyPolicyText {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`

export default NewsletterPage
